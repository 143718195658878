const withDefaultPageProps = page =>
  Object.assign(
    {
      numbers: {},
      showInLoginMenu: false,
      showInMemberMenu: true,
      social: true,
      socialHome: false,
      urlOverride: null,
      visibleForRoles: [],
    },
    page,
  );

const addApplications = (membersAPI, applications) =>
  membersAPI.addApplications(applications.map(withDefaultPageProps));

const installInParallel = addApplications;

export default {
  installInParallel,
};
