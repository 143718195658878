import { blogAppDefId } from './apps';
import { SECTIONS } from './sections';

const memberPages = {
  [SECTIONS.POSTS]: {
    numbers: {
      key: 'postsCount',
      default: 0,
    },
    method: 'addSection',
    appDefinitionId: blogAppDefId,
    pageId: 'member-posts-page',
    urlOverride: 'blog-posts',
    visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
  },
  [SECTIONS.COMMENTS]: {
    numbers: {
      key: 'commentsCount',
      default: 0,
    },
    method: 'addSection',
    appDefinitionId: blogAppDefId,
    pageId: 'member-comments-page',
    urlOverride: 'blog-comments',
    menuOrder: 2,
  },
  [SECTIONS.LIKES]: {
    numbers: {
      key: 'likesCount',
      default: 0,
    },
    method: 'addSection',
    appDefinitionId: blogAppDefId,
    pageId: 'member-likes-page',
    urlOverride: 'blog-likes',
    menuOrder: 2,
  },
  [SECTIONS.DRAFTS]: {
    numbers: {
      key: 'draftsCount',
      default: 0,
    },
    method: 'addSection',
    appDefinitionId: blogAppDefId,
    pageId: 'member-drafts-page',
    social: false,
    showInLoginMenu: true,
    urlOverride: 'my-drafts',
    menuOrder: 2,
    visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
  },
};

export default memberPages;
