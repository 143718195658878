import {
  EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR,
  EXPERIMENT_ONE_COLUMN_MAX_HEIGHT_SETTING,
} from '@wix/communities-blog-experiments';
import { isLayoutProGallery } from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import mapStyleParamsToPG from './map-pg-layout-styles';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { blogAppDefId, membersAreaAppDefId } from '../constants/apps';
import { PROVISIONING_MODAL } from '../constants/modal-panels';
import { getPanelOptions } from './settings-panels';
import { ALL_SECTIONS } from '../constants/sections';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { getStyleProperty, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL } from '../constants/style-params';
import dependentApps from '../constants/dependent-apps';
import bi from './bi';
import { getSiteMemberId } from './instance';
import sections from './sections';
import monitoring from './monitoring';
import experiments from './experiments';
import page from './page';
import memberPages from '../constants/member-pages';
import { LAYOUT_PANEL, MAIN_PANEL } from '../constants/settings-panels';
import { openModalPanel } from './modal';
import { BLOG_WIDGET_ID } from '../constants/widgets';

export const getMembersApi = async ({ sdk, appToken }) =>
  sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAreaAppDefId });

export const installMissingPostPage = async ({ sdk, appToken, instance }) => {
  const userId = await getSiteMemberId(sdk, appToken);
  if (userId === AUTOPILOT_USER_UUID) {
    return;
  }

  let currentPage, isPostPageMissing;
  try {
    [currentPage, isPostPageMissing] = await Promise.all([
      sdk.document.pages.getPrimary(),
      page.isPostPageMissing({ sdk, appToken, instance }),
    ]);
  } catch (e) {
    console.log('Error checking for missing post page:');
    console.log(e);
  }

  if (isPostPageMissing) {
    await sdk.tpa.add.component(appToken, {
      componentType: 'PAGE',
      appDefinitionId: blogAppDefId,
      page: { pageId: TPA_PAGE_ID_POST },
    });

    return sdk.document.pages.navigateTo(appToken, { pageRef: currentPage });
  }

  return Promise.resolve();
};

export const initProvision = ({ sdk }) => monitoring.toMonitored('sdk.document.save', sdk.document.save(), false);

export const initBiService = async ({ sdk, appToken }) => {
  bi.init(await sdk.document.info.getAppInstance(appToken));
};

export const displayProvisioningModal = async ({ sdk, appToken, isADI }) => {
  try {
    bi.provisioningModalDisplayed();
    const { action, value } = await openModalPanel({ sdk, appToken, isADI, modal: PROVISIONING_MODAL });
    bi.provisioningModalClosed(action, value);

    const shouldInstallMembersArea = action === 'next' && value;
    if (shouldInstallMembersArea) {
      return monitoring.toMonitored(
        'install-members-area',
        sdk.application.install(appToken, {
          appDefinitionId: membersAreaAppDefId,
          initiatorAppDefinitionId: blogAppDefId,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
};

export const installBlogSections = async ({ sdk, appToken }) => {
  const isInstalled = await sdk.tpa.isApplicationInstalled(appToken, { appDefinitionId: blogAppDefId });
  if (!isInstalled) {
    return;
  }
  await initProvision({ sdk, appToken });

  const membersAPI = await getMembersApi({ sdk, appToken });

  const apps = ALL_SECTIONS.map(name => memberPages[name] || dependentApps[name]);
  return (
    membersAPI &&
    monitoring.toMonitored(
      'install-members-area-apps-in-parallel',
      sections.installInParallel(membersAPI, apps).then(() => setDependantApps({ sdk, appToken })),
    )
  );
};

export async function setDependantApps({ sdk, appToken }) {
  const membersAPI = await getMembersApi({ sdk, appToken });
  const dependencies = Object.keys(dependentApps).map(app => dependentApps[app].appDefinitionId);
  return monitoring.toMonitored(
    'set-dependant-apps',
    membersAPI
      ? membersAPI.setDependantApps({
          appId: blogAppDefId,
          dependencies,
        })
      : Promise.resolve(),
  );
}
const openSettingsPanel = panelType => async ({ sdk, appToken, instance }, componentRef, componentRole) => {
  const options = await getPanelOptions({ sdk, instance, componentRef, panelType, componentRole });

  return sdk.editor.openComponentPanel(appToken, {
    componentRef,
    ...options,
  });
};

const openMainSettings = openSettingsPanel(MAIN_PANEL);
const openLayoutSettings = openSettingsPanel(LAYOUT_PANEL);

export const setStyleParams = async ({ sdk, appToken, isADI, isClassicEditor }) => {
  const isMapToPGEnabled = experiments.isEnabled(EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR);

  if (isADI || (isClassicEditor && !isMapToPGEnabled)) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);
  const blogComponent = blogAppComponents.find(component => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, { id: blogComponent.id });
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const homepageLayoutType = getStyleProperty(blogComponentStyle, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL);

  if (isLayoutProGallery(homepageLayoutType)) {
    return;
  }

  const isOneColumnPGLayoutEnabled = experiments.isEnabled(EXPERIMENT_ONE_COLUMN_MAX_HEIGHT_SETTING);
  const styleParams = mapStyleParamsToPG(blogComponentStyle, isClassicEditor, isOneColumnPGLayoutEnabled);

  if (styleParams.length < 1) {
    return;
  }

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams,
  });
};

export default {
  getMembersApi,
  initProvision,
  initBiService,
  installBlogSections,
  installMissingPostPage,
  setDependantApps,
  displayProvisioningModal,
  openLayoutSettings,
  openMainSettings,
  setStyleParams,
};
