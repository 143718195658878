import {
  getPGMappedLayout,
  getLayoutTypeById,
  LAYOUT_CARD_MEDIUM,
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_GRID,
  LAYOUT_CARD_SMALL,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_GRID,
  LAYOUT_TEXT_ON_IMAGE_SMALL,
  LAYOUT_CARD_LARGE,
  LAYOUT_PG_ONE_COLUMN,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import {
  getLayoutContentHeightParam,
  getLayoutImageRatioParam,
  getLayoutPostSizeParam,
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  getStyleProperty,
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM,
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
  CATEGORY_LAYOUT_TYPE_STYLE_PARAM,
  CATEGORY_LAYOUT_TYPE_STYLE_PARAM_FULL,
  AUTHOR_NAME_STYLE_ENABLED_PARAM_FULL,
  POST_PUBLISH_DATE_ENABLED_STYLE_PARAM_FULL,
  IS_READING_TIME_ENABLED_PARAM_FULL,
  IS_VIEW_COUNT_ENABLED_PARAM_FULL,
  IS_COMMENT_COUNT_ENABLED_PARAM_FULL,
  IS_LIKE_COUNT_ENABLED_STYLE_PARAM_FULL,
  IS_POST_DESCRIPTION_ENABLED_PARAM_FULL,
  IS_POST_DESCRIPTION_ENABLED_PARAM,
} from '../constants/style-params';

const isStringFalse = str => str === 'false';

const prepareStyleParamForSDK = (type, key, value) => ({
  type,
  key,
  param: {
    value,
  },
});

const isWithoutTopMetadata = blogComponentStyle => {
  const isAuthorNameEnabled = getStyleProperty(blogComponentStyle, AUTHOR_NAME_STYLE_ENABLED_PARAM_FULL);
  const isPostPublishDateEnabled = getStyleProperty(blogComponentStyle, POST_PUBLISH_DATE_ENABLED_STYLE_PARAM_FULL);
  const isReadingTimeEnabled = getStyleProperty(blogComponentStyle, IS_READING_TIME_ENABLED_PARAM_FULL);

  if (
    isStringFalse(isAuthorNameEnabled) &&
    isStringFalse(isPostPublishDateEnabled) &&
    isStringFalse(isReadingTimeEnabled)
  ) {
    return true;
  }

  return false;
};

const isWithoutBottomMetadata = blogComponentStyle => {
  const isViewCountEnabled = getStyleProperty(blogComponentStyle, IS_VIEW_COUNT_ENABLED_PARAM_FULL);
  const isCommentCountEnabled = getStyleProperty(blogComponentStyle, IS_COMMENT_COUNT_ENABLED_PARAM_FULL);
  const isLikeCountEnabled = getStyleProperty(blogComponentStyle, IS_LIKE_COUNT_ENABLED_STYLE_PARAM_FULL);

  if (isStringFalse(isViewCountEnabled) && isStringFalse(isCommentCountEnabled) && isStringFalse(isLikeCountEnabled)) {
    return true;
  }

  return false;
};

const isWithoutPostDescription = blogComponentStyle => {
  const withoutPostDescription = getStyleProperty(blogComponentStyle, IS_POST_DESCRIPTION_ENABLED_PARAM_FULL);

  if (isStringFalse(withoutPostDescription)) {
    return true;
  }

  return false;
};

const calculateContentHeight = (layoutType, withoutTopMetadata, withoutBottomMetadata, withoutPostDescription) => {
  let contentHeight = 320;
  let topMetadataHeight = 42;
  let bottomMetadataHeight = 42;
  const postDescriptionHeight = 118;

  switch (layoutType) {
    case LAYOUT_CARD_SMALL:
      contentHeight = 300;
      break;
    case LAYOUT_GRID:
      contentHeight = 250;
      break;
    case LAYOUT_CARD_LARGE:
      contentHeight = 310;
      topMetadataHeight = 62;
      bottomMetadataHeight = 62;
      break;
    default:
      break;
  }

  if (withoutTopMetadata) {
    contentHeight = contentHeight - topMetadataHeight;
  }

  if (withoutBottomMetadata) {
    contentHeight = contentHeight - bottomMetadataHeight;
  }

  if (withoutPostDescription) {
    contentHeight = contentHeight - postDescriptionHeight;
  }

  return contentHeight;
};

const mapToPGLayout = (
  layoutType,
  isClassicEditor,
  withoutTopMetadata,
  withoutBottomMetadata,
  withoutPostDescription,
  isOneColumnPGLayoutEnabled,
  categoryPage = false,
) => {
  const defaultLayoutType = isClassicEditor ? LAYOUT_PG_SIDE_BY_SIDE : LAYOUT_PG_GRID;
  layoutType = layoutType && Number(layoutType);

  const section = categoryPage ? SECTION_CATEGORY : SECTION_HOMEPAGE;
  const LAYOUT_PARAM = categoryPage ? CATEGORY_LAYOUT_TYPE_STYLE_PARAM : HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM;

  let contentHeightParam;
  let postSizeParam;
  let imageRatioParam;
  let contentHeightValue;

  if (layoutType !== undefined) {
    const PGLayout = getPGMappedLayout(layoutType, isOneColumnPGLayoutEnabled);
    const layoutName = getLayoutTypeById(PGLayout);

    contentHeightParam = getLayoutContentHeightParam(section, layoutName);
    imageRatioParam = getLayoutImageRatioParam(section, layoutName);
    postSizeParam = getLayoutPostSizeParam(section, layoutName);
    contentHeightValue = calculateContentHeight(
      layoutType,
      withoutTopMetadata,
      withoutBottomMetadata,
      withoutPostDescription,
    );
  }

  const PGOneColumnParams = [
    prepareStyleParamForSDK('number', contentHeightParam, contentHeightValue),
    prepareStyleParamForSDK('number', imageRatioParam, 6),
    ...(isOneColumnPGLayoutEnabled
      ? [prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_ONE_COLUMN)]
      : [
          prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_GRID),
          prepareStyleParamForSDK('number', postSizeParam, 981),
        ]),
  ];

  switch (layoutType) {
    case LAYOUT_CARD_MEDIUM:
      return [
        prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_CARD_MEDIUM),
        prepareStyleParamForSDK('number', contentHeightParam, contentHeightValue),
      ];
    case LAYOUT_CARD_SMALL:
      return [
        prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_CARD_MEDIUM),
        prepareStyleParamForSDK('number', contentHeightParam, contentHeightValue),
        prepareStyleParamForSDK('number', postSizeParam, 292),
      ];
    case LAYOUT_SIDE_BY_SIDE:
      return [prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_SIDE_BY_SIDE)];
    case LAYOUT_TEXT_ON_IMAGE_MEDIUM:
      return [prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM)];
    case LAYOUT_GRID:
      return [
        prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_GRID),
        prepareStyleParamForSDK('number', contentHeightParam, contentHeightValue),
        prepareStyleParamForSDK('number', imageRatioParam, 1),
        prepareStyleParamForSDK('boolean', IS_POST_DESCRIPTION_ENABLED_PARAM, false),
      ];
    case LAYOUT_TEXT_ON_IMAGE_SMALL:
      return [
        prepareStyleParamForSDK('number', LAYOUT_PARAM, LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM),
        prepareStyleParamForSDK('number', postSizeParam, 292),
      ];
    case LAYOUT_CARD_LARGE:
      return PGOneColumnParams;
    case undefined:
      return [prepareStyleParamForSDK('number', LAYOUT_PARAM, defaultLayoutType)];
    default:
      return [];
  }
};

const mapStyleParamsToPG = (blogComponentStyle, isClassicEditor, isOneColumnPGLayoutEnabled) => {
  const homepageLayoutType = getStyleProperty(blogComponentStyle, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL);
  const categoryLayoutType = getStyleProperty(blogComponentStyle, CATEGORY_LAYOUT_TYPE_STYLE_PARAM_FULL);

  const withoutTopMetadata = isWithoutTopMetadata(blogComponentStyle);
  const withoutBottomMetadata = isWithoutBottomMetadata(blogComponentStyle);
  const withoutPostDescription = isWithoutPostDescription(blogComponentStyle);

  const mappedHomepageParams = mapToPGLayout(
    homepageLayoutType,
    isClassicEditor,
    withoutTopMetadata,
    withoutBottomMetadata,
    withoutPostDescription,
    isOneColumnPGLayoutEnabled,
  );
  const mappedCategoryPageParams = mapToPGLayout(
    categoryLayoutType,
    isClassicEditor,
    withoutTopMetadata,
    withoutBottomMetadata,
    withoutPostDescription,
    isOneColumnPGLayoutEnabled,
    true,
  );

  const mappedParams = [...mappedHomepageParams, ...mappedCategoryPageParams];

  return mappedParams;
};

export default mapStyleParamsToPG;
