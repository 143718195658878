import { get } from 'lodash';
import { blogAppDefId, membersAreaAppDefId } from './constants/apps';
import monitoring from './services/monitoring';
import actions from './services/actions';
import handlers from './services/handlers';
import { RAVEN_INIT_STRING, FEDOPS_APP_NAME } from './constants/monitoring';
import { getMetaSiteId } from './services/instance';
import postPageMigration from './services/post-page-migration';

let context = {};

export const editorReady = (sdk, appToken, options) => {
  // eslint-disable-next-line
  console.log('blog platform app is up');
  process.env.NODE_ENV === 'development' && console.log('options:', options);

  const instance = get(options, 'initialAppData.instance');
  const instanceId = get(options, 'initialAppData.instanceId');
  const metaSiteId = getMetaSiteId(instance);
  const origin = get(options, 'origin.type');

  monitoring.init(FEDOPS_APP_NAME, RAVEN_INIT_STRING, { metaSiteId, instanceId });
  context = {
    sdk,
    appToken,
    isADI: origin === 'ADI' || origin === 'ADI_MA',
    isClassicEditor: origin === 'CLASSIC',
    instance,
  };

  return handlers.handleEditorReady(context);
};

export const handleAction = async ({ type, payload }) => {
  try {
    switch (type) {
      case 'appInstalled': {
        await postPageMigration.logInstalledApp(context, payload.appDefinitionId);

        switch (payload.appDefinitionId) {
          case blogAppDefId:
            return handlers.handleBlogInstalled(context);
          case membersAreaAppDefId:
            return actions.installBlogSections(context);
          default:
            return Promise.resolve();
        }
      }
      case 'migrate':
        return handlers.handleMigrateAction(context, payload);
      default:
        return Promise.resolve();
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const onEvent = event => handlers.handleOnEvent(event, context);
export const getAppManifest = () => Promise.resolve(handlers.handleGetAppManifest(context));
export const getControllerPresets = () => Promise.resolve([]);
